// ==========================================================================
// Base / Fonts
// ==========================================================================

@include font-face(
    $font-montserrat,
    "fonts/Montserrat/Montserrat-Regular"
) {
    font-style:  normal;
    font-weight: 400;
}

@include font-face(
    $font-montserrat,
    "fonts/Montserrat/Montserrat-Medium"
) {
    font-style:  normal;
    font-weight: 500;
}

@include font-face(
    $font-montserrat,
    "fonts/Montserrat/Montserrat-SemiBold"
) {
    font-style:  normal;
    font-weight: 600;
}

@include font-face(
    $font-montserrat,
    "fonts/Montserrat/Montserrat-Bold"
) {
    font-style:  normal;
    font-weight: 700;
}

@include font-face(
    $font-montserrat,
    "fonts/Montserrat/Montserrat-ExtraBold"
) {
    font-style:  normal;
    font-weight: 800;
}

@include font-face(
    $font-rubik,
    "fonts/Rubik/Rubik-Regular"
) {
    font-style:  normal;
    font-weight: 400;
}

@include font-face(
    $font-rubik,
    "fonts/Rubik/Rubik-Medium"
) {
    font-style:  normal;
    font-weight: 500;
}

@include font-face(
    $font-rubik,
    "fonts/Rubik/Rubik-SemiBold"
) {
    font-style:  normal;
    font-weight: 600;
}

@include font-face(
    $font-rubik,
    "fonts/Rubik/Rubik-Bold"
) {
    font-style:  normal;
    font-weight: 700;
}