.c-nav {
	position: fixed;
	top: 0;
	left: 0;
	right: 0;
	z-index: 9;
	visibility: hidden;
	transform: translateY(calc(-100% - #{rem($header_height)}));
	transition: 1s $easing2;
	height: 100vh;

	.c-nav_bg {
		position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
		z-index: 1;
        // opacity: 0;
        transition: 1s $easing2;
		// background-color: rgba($black, 0.29);
	}

	.c-nav_container {
		position: relative;
		z-index: 2;
		padding: 0 rem($unit-small);
		overflow-x: auto;
		max-height: 100vh;
		@media (max-width: $to-small) {
			padding: 0;
		}
	}
	
	.c-nav_wrap {
		padding: rem($unit + $unit-tiny) rem($unit-small) rem($unit-small);
		background-color: $white;
		border-radius: 0 0 rem($unit-tiny) rem($unit-tiny);
		min-width: 600px;
		@media (max-width: $to-small) {
            min-width: auto;
        }
	}
  
	.c-nav-primary_link {
		&:hover, &.is-active {
			color: $primary;
		}
		svg {
			width: rem(22px);
		}
	}

	.c-nav_adherent-deco {
		position: absolute;
		z-index: 1;
		left: -120%;
		top: 4rem;
		filter: drop-shadow(0 10px 30px rgba(0, 0, 0, 0.1));
		svg {
			transform: rotate(190deg);
			width: 150%;
		}
	}

	.c-nav_adherent-content {
		position: relative;
		z-index: 2;
	}

	&.-adherent, &.-search, &.-login {
		.c-nav_container {
			display: flex;
			justify-content: flex-end;
			@media (max-width: $to-small) {
				display: block;
			}
		}

		.b-liens .b-liens_item .b-liens_item_deco {
			width: 180%;
			top: rem(-$unit);
		}
	}

	&.-search {
		.c-form_input {
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
		}
		.c-button {
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
}

.c-nav_open_bg {
	position: fixed;
	z-index: 8;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	opacity: 0;
	visibility: hidden;
	transition: 1s $easing2;
	background-color: rgba($black, 0.29);
}

.has-navOpen {
	.c-nav_open_bg {
		opacity: 1;
		visibility: visible;
	}
	.c-nav {
		&:not(.-adherent, .-search, .-login) {
			visibility: visible;
			transform: translateY(0);
			transition: transform 1s $easing2;
		}
	}
}

.has-navAdherentOpen {
	.c-nav_open_bg {
		opacity: 1;
		visibility: visible;
	}
	.c-nav {
		&.-adherent {
			visibility: visible;
			transform: translateY(0);
			transition: transform 1s $easing2;
		}
	}
}

.has-navSearchOpen {
	.c-nav_open_bg {
		opacity: 1;
		visibility: visible;
	}
	.c-nav {
		&.-search {
			visibility: visible;
			transform: translateY(0);
			transition: transform 1s $easing2;
		}
	}
}

.has-navLoginOpen {
	.c-nav_open_bg {
		opacity: 1;
		visibility: visible;
	}
	.c-nav {
		&.-login {
			visibility: visible;
			transform: translateY(0);
			transition: transform 1s $easing2;
		}
	}
}