.b-relation-pages-picto {
    .b-relation-pages-picto_title-wrap {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }
    .b-relation-pages-picto_deco, .b-relation-pages-picto_content {
        grid-column: 1;
        grid-row: 1;
    }

    .b-relation-pages-picto_deco {
        pointer-events: none;
    }

    .b-relation-pages-picto_content {
        position: relative;
        z-index: 2;
    }
}