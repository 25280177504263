.b-assurance {
    .b-assurance_item {
        position: relative;
    }
    .b-assurance_item_deco {
        position: absolute;
        top: rem(-$unit);
        right: -30%;
    }
    .b-assurance_item_content {
        position: relative;
        z-index: 2;
    }
}