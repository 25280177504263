.u-c-primary {
    color: $primary;
}
.u-c-primary-light {
    color: rgba($primary, 0.08);
}
.u-c-second {
    color: $second;
}
.u-c-third {
    color: $third;
}
.u-c-fourth {
    color: $fourth;
}
.u-c-black {
    color: $black;
}
.u-c-black-light {
    color: rgba($black, 0.65);
}
.u-c-black-extra-light {
    color: rgba($black, 0.3);
}
.u-c-white {
    color: $white;
}
.u-c-white-light {
    color: rgba($white, 0.8);
}
.u-c-white-extralight {
    color: rgba($white, 0.5);
}
.u-bg-primary {
    background-color: $primary;
}
.u-bg-primary-light {
    background-color: rgba($primary, 0.08);
}
.u-bg-second {
    background-color: $second;
}
.u-bg-third {
    background-color: $third;
}
.u-bg-fourth {
    background-color: $fourth;
}
.u-bg-black {
    background-color: $black;
}
.u-bg-black-dark {
    background-color: $black-dark;
}
.u-bg-white {
    background-color: $white;
}

@each $name, $color in $dossers {
    .u-c-#{$name} {
        color: $color;
    }
    .u-bg-#{$name} {
        background-color: $color;
        color: $white;
    }
}

.u-bg-primary-linear {
    background: linear-gradient(-145deg, $primary, #1E334C);
}
.u-bg-second-linear {
    background: linear-gradient(120deg, $second, #3B86C9);
}
.u-bg-third-linear {
    background: linear-gradient(120deg, $third, #C04723);
}