.c-text{
    blockquote {
        text-align: center;
        font-family: $font-montserrat;
        font-weight: $bold;
        font-size: rem($font-size-h3);
        line-height: rem($font-size-h3 + 2px);
        margin: 0;
    }

    a:not(.c-button) {
        text-decoration: underline;
        color: $primary;
        word-break: break-all;
        &:hover {
            color: $black;
        }
    }
    ul {
        padding-left: 0;
        li {
            list-style-type: none;
            position: relative;
            padding-left: calc(#{rem(10px)} + #{rem($unit-tiny)});
            margin-bottom: rem($unit-tinier);
            &:last-of-type {
                margin-bottom: 0;
            }
            &::before {
                content: " ";
                position: absolute;
                left: 0;
                width: rem(6px);
                height: rem(6px);
                background-color: currentColor;
                border-radius: 50%;
                top: calc(#{$font-size} - #{rem(10px)});
            }
        }
    }
    ol {
        counter-reset: ol_counter; /* on initialise et nomme un compteur */
        padding: 0;
        li {
            padding: 0;
            list-style-type: none;
            counter-increment: ol_counter; /* on incrémente le compteur à chaque nouveau li */
            position: relative;
            padding-left: calc(#{rem(12px)} + #{rem($unit-tiny)});
            margin-bottom: rem($unit-tinier);
            &::before {
                content: counter(ol_counter)"."; /* on affiche le compteur */
                width: rem(12px);
                height: rem(12px);
                position: absolute;
                font-weight: $medium;
                left: 0;
            }
        }
    }
    img {
        width: 100%;
        border-radius: 8px;
        overflow: hidden;
    }

    &.u-c-white {
        a {
            color: inherit;
        }
    }
}
