.b-dashboard {
    min-height: 100vh;
    .b-dashboard_deco {
        position: absolute;
        min-width: 100%;
        left: 0;
        top: 0;
        z-index: -1;
        pointer-events: none;
        svg {
            min-width: 100%;
        }
    }

    .b-dashboard_icon {
        border-radius: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        width: rem(30px);
        height: rem(30px);
        background-color: $primary;
        color: $white;
        flex-shrink: 0;
        &.-big {
            width: rem(60px);
            height: rem(60px);
            svg {
                width: rem($unit-tiny);
            }
        }

        &.-black {
            background-color: $black;
        }
    }

}