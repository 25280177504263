.b-adhesion-page {
    .b-tiroirs {
        table {
            td {
                padding-bottom: 10px;
            }
        }
    }
}

.b-tiroirs {
    
}
