.b-cta{
    .o-ratio_content_bg {
        min-height: rem(300px);
    }

    .b-cta_deco {
        color: $primary;
        position: absolute;
        z-index: 1;
        top: rem(-150px);
        // left: rem(-150px);
        // width: 100%;
        right: 0;
        svg {
            transform: scale(-1, 1);
            // width: 110%;
        }
        @media (max-width: $to-small) {
            right: auto;
            left: -50%;
        }
    }

    .b-cta_content {
        position: relative;
        z-index: 2;
    }
}
