.b-relation-pages-liste {
    position: relative;
    overflow: hidden;
    .b-relation-pages-liste_deco {
        position: absolute;
        top: rem(-$unit);
        left: rem(-$unit);
        width: rem(300px);
        height: rem(300px);
        border-radius: 50%;
        padding: rem($unit);
        display: flex;
        align-items: flex-end;
        justify-content: flex-end;
        z-index: -1;
        pointer-events: none;

        svg {
            width: rem(100px);
        }
    }

    .b-relation-pages-liste_title {
        @media (max-width: $to-small) {
            flex-direction: row!important;
        }
    }
}