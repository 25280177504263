.b-liens {
    .b-liens_item {
        .b-liens_item_wrap {
            position: relative;
            &:hover {
                .c-button_icon {
                    &::before {
                        width: rem(40px);
                        height: rem(40px);
                    }
                }
            }

            .b-liens_restricted {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                background-color: rgba($white, 0.66);
                z-index: 5;
            }
        }
        .b-liens_item_deco {
            position: absolute;
            width: 110%;
            right: rem(-80px);
            top: rem(-20px);
            svg {
                width: 100%;
            }
        }
        // &:nth-child(1n) {
        //     .b-liens_item_wrap {
        //         background-color: $fourth;
        //     }
        //     .b-liens_item_deco {
        //         color: $fourth;
        //     }
        // }
        // &:nth-child(2n) {
        //     .b-liens_item_wrap {
        //         background-color: $third;
        //     }
        //     .b-liens_item_deco {
        //         color: $third;
        //     }
        // }
        // &:nth-child(3n) {
        //     .b-liens_item_wrap {
        //         background-color: $second;
        //     }
        //     .b-liens_item_deco {
        //         color: $second;
        //     }
        // }
        // &:nth-child(4n) {
        //     .b-liens_item_wrap {
        //         background-color: $white;
        //         border: 1px solid $black;
        //         .b-liens_item_deco {
        //             color: $white;
        //         }
        //         .c-button {
        //             color: $white;
        //             .c-button_icon {
        //                 &::before {
        //                     background-color: $black;
        //                 }
        //             }
        //         }
        //     }
        // }

        .u-bg-fourth, .u-bg-primary, .u-bg-black {
            .c-heading {
                color: $white;
            }
        }

        .u-bg-white {
            background-color: $white;
            border: 1px solid $black;
            .b-liens_item_deco {
                color: $white;
            }
            .c-button {
                color: $white;
                .c-button_icon {
                    &::before {
                        background-color: $black;
                    }
                }
            }
        }

        .is-disabled {
            opacity: 0.5;
            cursor: default;
        }
    }
}