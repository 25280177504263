.c-accordion_item {
  border: 2px solid $black;
  border-radius: rem(8px);
  .c-accordion_header {
    cursor: pointer;
    position: relative;
    display: grid;
    grid-template-columns: 1fr rem(40px);
    gap: rem($unit-small);
    justify-content: space-between;
    align-items: center;
    transition: color 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
    padding: rem($unit-tiny);
  }
  .c-accordion_plus_wrap {
    position: relative;
    width: rem(32px);
    height: rem(32px);
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
      &::before {
        content: "";
        position: absolute;
        top: 50%;
        left: 50%;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        transform: translate(-50%, -50%);
        transition: $speed $easing2;
        z-index: -1;
        background-color: $third;
    }
  }
  .c-accordion_plus {
    position: relative;
    width: rem($unit-tiny);
    height: rem($unit-tiny);
    transition: transform 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
    flex-shrink: 0;
    &::after, &::before {
      content: "";
      display: block;
      position: absolute;
      transition: border-color 0.6s cubic-bezier(0.075, 0.82, 0.165, 1), transform 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
    }
    &::before {
      border-top: 2px solid $black;
      top: 50%;
      left: 0;
      right: 0;
      margin-top: -1px;
    }
    &::after {
      border-left: 2px solid $black;
      top: 0;
      bottom: 0;
      left: 50%;
      margin-left: -1px;
    }
  }
  .c-accordion_main {
    height: 0;
    overflow: hidden;
  }

  .c-accordion_main_wrap {
    opacity: 0;
    transform: translateY(100%);
    transition: opacity 0.9s cubic-bezier(0.075, 0.82, 0.165, 1), transform 0.9s cubic-bezier(0.075, 0.82, 0.165, 1);
  }
  
  &.is-open {
    .c-accordion_main {
      height: auto;
      padding: rem($unit-small) rem($unit-tiny);
      padding-top: 0;
    }
    .c-accordion_main_wrap {
      opacity: 1;
      transform: translateY(0);
      padding-top: rem($unit-small);
      border-top: 2px solid $black;
    }
    .c-accordion_plus::after {
      transform: scale(0);
    }
  }

  &:hover, &.is-open {
    .c-accordion_plus_wrap {
      &::before {
        width: rem(40px);
        height: rem(40px);
      }
    }
  }

}
