// ==========================================================================
// Utilities / Helpers
// ==========================================================================

// Layout
// ==========================================================================
.u-clearfix {
    @include u-clearfix;
}

// Decorative
// =============================================================================
.u-truncate {
    @include u-truncate;
}

.u-extrabold {
    font-weight: $extrabold;
  }
.u-bold {
  font-weight: $bold;
}
.u-semibold {
    font-weight: $semibold;
  }
.u-medium {
  font-weight: $medium;
}
.u-light {
  font-weight: $light;
}

.u-font-montserrat {
    font-family: $font-montserrat;
}
.u-font-rubik {
    font-family: $font-rubik;
}

.u-h-100 {
    height: 100%;
}
.u-w-100 {
    width: 100%;
}
.u-svg-mw-100 {
    svg {
        max-width: 100%;
    }
}
.u-uppercase {
    text-transform: uppercase;
}
.u-lowercase {
    text-transform: lowercase;
}
.u-underline {
    text-decoration: underline;
}
.u-p-small {
    font-size: rem($font-size-small);
}
.u-p {
    font-size: rem(20px);
}
.u-radius {
    border-radius: 8px;
}

.u-overflow-hidden {
    overflow: hidden;
}

.u-margin-top-auto {
    margin-top: auto;
}

.u-padding-header {
    padding-top: rem($header_height);
}

.u-relative {
    position: relative;
}

.u-border {
    border: 1px solid $black;
    &.-big {
        border-width: 2px;
    }
    &.-white {
        border-color: $white;
    }
}

.u-box-shadow {
    box-shadow: 0 5px 10px rgba($black, 0.16);
}

.u-fit-content{
    width: fit-content;
}

.u-svg-filter {
    svg {
        filter: drop-shadow(0 10px 30px rgba(0, 0, 0, 0.32));
    }
    &.-light {
        svg {
            filter: drop-shadow(0 10px 30px rgba(0, 0, 0, 0.10));
        }   
    }
    &.-extra-light {
        svg {
            filter: drop-shadow(0 10px 30px rgba(0, 0, 0, 0.06));
        }
    }
}
.u-svg-clip {
    position: absolute;
    width: 0;
    height: 0;
}

//  Visibility / Display
// ==========================================================================
[hidden][aria-hidden="false"] {
    position: absolute;
    display: inherit;
    clip: rect(0, 0, 0, 0);
}

[hidden][aria-hidden="false"]:focus {
    clip: auto;
}

.u-flex {
    display: flex;
    &.-middle {
        align-items: center;
    }
    &.-bottom {
        align-items: flex-end;
    }
    &.-top {
        align-items: flex-start;
    }
    &.-column {
        flex-direction: column;
    }
    &.-end {
        justify-content: flex-end;
    }
    &.-start {
        justify-content: flex-start;
    }
    &.-center {
        justify-content: center;
    }
    &.-space {
        justify-content: space-between;
    }
    &.-wrap {
        flex-wrap: wrap;
    }
    &.-gap-tinier{
        gap: rem($unit-tinier);
    }
    &.-gap-tiny{
        gap: rem($unit-tiny);
    }
    &.-gap-small {
        gap: rem($unit-small);
    }
    &.-gap {
        gap: rem($unit);
    }
    &.-gap-large {
        gap: rem($unit-large);
        @media (max-width: $to-small) {
            gap: rem($unit-small);
        }
    }

    &.-gap-h-tiny {
        row-gap: rem($unit-tiny);
    }
}

.u-flex-1 {
    flex: 1;
}
.u-flex-2 {
    flex: 2;
}
.u-flex-3 {
    flex: 3;
}

// .u-block {
//     display: block;
// }

// /**
//  * 1. Fix for Firefox bug: an image styled `max-width:100%` within an
//  * inline-block will display at its default size, and not limit its width to
//  * 100% of an ancestral container.
//  */
// .u-inline-block {
//     display: inline-block !important;
//     max-width: 100%; /* 1 */
// }

// .u-inline {
//     display: inline !important;
// }

// .u-table {
//     display: table !important;
// }

// .u-tableCell {
//     display: table-cell !important;
// }

// .u-tableRow {
//     display: table-row !important;
// }

/**
 * Completely remove from the flow but leave available to screen readers.
 */
.u-screen-reader-text {
    @include u-accessibly-hidden;
}

@media not print {
    .u-screen-reader-text\@screen {
        @include u-accessibly-hidden;
    }
}

/*
 * Extends the `.screen-reader-text` class to allow the element
 * to be focusable when navigated to via the keyboard.
 *
 * @link https://www.drupal.org/node/897638
 * @todo Define styles when focused.
 */
.u-screen-reader-text.-focusable {
    @include u-accessibly-focusable;
}
