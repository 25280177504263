.b-dialogue {
    .b-dialogue_bulle {
        width: rem(200px);
        height: rem(200px);
        border-radius: 50%;
        position: relative;
        z-index: -1;
        &.-right {
            opacity: 0.5;
            margin-left: auto;
        }
    }

    .b-dialogue_item {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
    }

    .b-dialogue_content, .b-dialogue_bulle {
        grid-column: 1;
        grid-row: 1;
    }
}