.b-video {
    position: relative;
    .b-video_wrap {
        // position: absolute;
        // z-index: 2;
        // height: 100%;
        // width: 100%;
        // top: 0;
        // left: 0;
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($black, 0.48);
            z-index: 1;
        }
    }
    .b-video_content {
        position: relative;
        z-index: 2;
    }
    
}