.c-card {
    position: relative;
    overflow: hidden;

    &.-page {
        
    }
    &.-actualite {
        .c-card_image {
            overflow: hidden;
            border-radius: 8px;
            position: relative;
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background-color: rgba($black, 0.16);
                z-index: 1;
            }

            .c-button {
                position: absolute;
                z-index: 2;
                right: rem($unit-tiny);
                bottom: rem($unit-tiny);
            }
        }

        &:hover {
            .c-button_icon {
                &::before {
                    width: rem(40px);
                    height: rem(40px);
                }
            }
        }
    }

    &.-actualite-une {
        .c-card_deco {
            color: $black;
            position: absolute;
            z-index: 1;
            top: rem(-150px);
            // left: rem(-150px);
            right: 0;
            // width: 100%;
            svg {
                transform: scale(-1, 1);
                // width: 100%;
            }
            @media (max-width: $to-small) {
                right: auto;
                left: -50%;
            }
        }
        .c-card_content {
            position: relative;
            z-index: 2;
        }
        .c-button {
            position: absolute;
            z-index: 2;
            right: rem($unit-tiny);
            bottom: rem($unit-tiny);
        }
        &:hover {
            .c-button_icon {
                &::before {
                    width: rem(40px);
                    height: rem(40px);
                }
            }
        }
    }

    &.-file {
        .c-card_wrap {
            border: 2px solid $black;
            border-radius: 8px;
        }

        &:hover {
            .c-button {
                &::before {
                    top: 0;
                }
            }
        }
    }

    &.-thematique {
        .c-card_wrap {
            border: 2px solid $black;
            border-radius: 8px;
            overflow: hidden;
        }

        .c-card_deco_wrap {
            position: relative;
            height: rem(190px);
            .c-card_deco {
                position: absolute;
                top: rem(-50px);
                left: rem(-50px);
                border-radius: 50%;
                overflow: hidden;
                z-index: -1;
            }
        }

        .c-card_foot {
            display: grid;
            grid-template-columns: 1fr rem(32px);
            gap:rem($unit-small);
            align-items: center;
        }

        &:hover {
            .c-button {
                &::before {
                    top: 0;
                }
            }
        }
    }

    &.-equipe {
        .o-ratio_content {
            
        }
        .c-card_icon {
            svg {
                width: rem(80px);
            }
        }
        .c-badge_wrap {
            position: absolute;
            bottom: 0;
            left: 0;
            margin: rem($unit-tiny);
            z-index: 2;
        }
    }

    &.-temoignage {
        .c-card_image {
            background-color: $white;
            border-radius: 50%;
            svg {
                width: rem($unit-small);
            }
        }
    }
}
