.b-accueil-slider {
    // overflow: hidden;
    clip-path: url(#my-clip-path);
    

    .b-accueil-slider_slide {
        min-height: calc(100vh - (#{rem($unit-large)} * 2));
        height: calc(100% - (#{rem($unit-large)} * 2));
        position: relative;
    }
    
    .b-accueil-slider_slide-image {
        position: absolute;
        // top: -85px;
        // left: -150px;
        // width: 120%;
        // height: 120%;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        // clip-path: url(#accueil-slider-clip-path);
        &::after {
            content: "";
            position: absolute;
            z-index: 1;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: linear-gradient(45deg, rgba($black, 0.8), transparent);
        }

        mask: url('../images/masque_photo.svg');
        mask-repeat: no-repeat;
        mask-size: cover;
        mask-position: center center;
    }

    .b-accueil-slider_courbe {
        position: absolute;
        // color: $white;
        width: 100%;
        bottom: 0;
        left: 0;
        // z-index: 5;
        z-index: -1;
    }

    .o-container {
        position: relative;
        z-index: 2;
    }

    .c-slider_container {
        overflow: visible !important;
    }
}