.c-button {
    padding: rem($unit-tiny) rem($unit-tiny);
    transition: $speed $easing2;
    display: inline-flex;
    align-items: center;
    background-color: $third;
    position: relative;
    border-radius: 4px;
    font-weight: $medium;
    text-transform: uppercase;
    overflow: hidden;
    gap: rem($unit-small);
    text-align: left;

    &.u-w-100, &.u-1\/1\@to-tiny {
        justify-content: space-between;
    }

    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 100%;
        transition: $speed $easing;
        z-index: 1;
        background-color: rgba($fourth, 0.1);
    }

    .c-button_label {
        position: relative;
        z-index: 2;
    }

    .c-button_icon {
        position: relative;
        z-index: 2;
        width: rem(32px);
        height: rem(32px);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        flex-shrink: 0;
        &::before {
            content: "";
            position: absolute;
            top: 50%;
            left: 50%;
            width: 100%;
            height: 100%;
            border-radius: 50%;
            transform: translate(-50%, -50%);
            transition: $speed $easing2;
            z-index: -1;
            background-color: $white;
        }
        svg {
            position: relative;
            z-index: 2;
        }
    }

    &.-white {
        background-color: $white;
    }

    &.-second {
        background-color: $second;
    }

    &.-third {
        background-color: $third;
    }

    &.-fourth {
        background-color: $fourth;
    }

    &.-primary {
        background-color: $primary;
    }

    &.-black {
        background-color: $black;
        color: $white;
        .c-button_icon {
            color: $black;
        }
    }

    &.-black-light {
        background-color: rgba($black, 0.13);
        font-weight: $normal;
        font-size: rem($font-size-h6);
        text-transform: none;
    }

    &.-icon-third {
        .c-button_icon {
            &::before {
                background-color: $third;
            }
        }
    }

    &.-outline {
        background-color: transparent;
        border: 1px solid $black;
    }

    &.-round {
        width: rem(32px);
        height: rem(32px);
        justify-content: center;
        padding: 0;
        border-radius: 50%;
        flex-shrink: 0;
        &.-big {
            width: rem(44px);
            height: rem(44px);
        }
        &.-big2 {
            width: rem(70px);
            height: rem(70px);
        }
    }

    &.-transparent {
        background-color: transparent;
    }

    @include u-hocus {
        &::before {
            top: 0;
        }
      
        .c-button_icon {
            &::before {
                width: rem(40px);
                height: rem(40px);
            }
        }
    }

    &.-disabled {
        cursor: default;
        opacity: 0.5;
    }

    &.-prev {
        svg {
            transform: rotate(180deg);
        }
    }
}


button {
    &:hover {
        .c-button {
            &::before {
                top: 0;
            }
        }
    }
}

.c-button_link {
    position: relative;
    overflow: hidden;
    font-size: rem(18px);

    &::before {
        content: "";
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 100%;
        transition: $speed $easing;
        z-index: 1;
        background-color: rgba($fourth, 0.1);
    }

    @include u-hocus {
        &::before {
            top: 0;
        }
    }
}

.svg-btn {
    color: $third;
    cursor: pointer;
    &:hover {
        color: $third-hover;
    }
    &.is-active {
        color: $primary;
    }
}