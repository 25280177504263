.c-icon {
    width: rem(30px);
    height: rem(30px);
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    &.-third {
        background-color: $third;
    }

    &.-medium {
        width: rem(48px);
        height: rem(48px);
    }
}