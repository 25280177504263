.c-table {
  max-width: 100%;
  overflow: auto;
  .c-table_tr {
    display: flex;
    gap: rem($unit-tiny);
    justify-content: space-between;
    word-break: break-all;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  .c-table_head {
    margin-bottom: rem($unit-tiny);
  }
  .c-table_td, .c-table_th {
    flex: 1;
    padding: rem($unit-tinier) 0;
  }
  .c-table_th {
    font-weight: $medium;
    color: $primary;
  }
}
