.b-chiffres {
    .b-chiffres_deco {
        position: absolute;
        top: rem(-80px);
        right: rem(-400px);
        color: $black;
        width: 100%;
    }
    .b-chiffres_content {
        position: relative;
        z-index: 2;
    }
    .b-chiffres_item {
        &:nth-child(1n) {
            .b-chiffres_nombre{
                    color: $fourth;
            }
        }
        &:nth-child(2n) {
            .b-chiffres_nombre{
                color: $third;
            }
        }
        &:nth-child(3n) {
            .b-chiffres_nombre{
                color: $second;
            }
        }
        &:nth-child(4n) {
            .b-chiffres_nombre{
                color: $primary;
            }
        }
        &:nth-child(6n) {
            .b-chiffres_nombre{
                color: $third;
            }
        }
    }
}