.c-dropdown {
    position: relative;
    z-index: 5;
    // .c-dropdown_selected {
    //     .c-button_icon {
    //         transform: rotate(90deg);
    //         transition: $speed $easing;
    //     }
    // }
    .c-dropdown_content {
        visibility: hidden;
        position: absolute;
        left: 0;
        top: 100%;
        padding-top: rem($unit-tiny);
        transition: $speed $easing;
        transform: translateY(rem(-$unit_tiny));
        opacity: 0;
    }
    
    .c-dropdown_content_container {
        background-color: $black;
        border-radius: 3px;
        border: 1px solid rgba($white, 0.35);

    }

    .c-dropdown_content_wrap {
        overflow: auto;
        min-width: 100%;
        width: rem(450px);
        text-align: left;
        max-height: rem(300px);
        max-width: calc(100vw - (#{rem($unit-small)}) * 2);
    }

    .c-dropdown_link {
        display: grid;
        color: $white;
        font-weight: $semibold;
        border-bottom: 1px solid rgba($white, 0.35);
        padding: rem($unit-tiny);
        width: 100%;
        text-align: left;
        &:last-of-type {
            border-bottom: none;
        }
        &.is-active {
            background-color: $primary;
        }
        &:hover {
            background-color: $primary;
        }
    }

    &.is-open {
        z-index: 6;
        .c-dropdown_content {
            visibility: visible;
            transform: translateY(0);
            opacity: 1;
        }
        // .c-dropdown_selected {
        //     .c-button_icon {
        //         transform: rotate(-90deg);
        //     }
        // }
    }
}