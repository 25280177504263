.b-onglet{
    overflow: hidden;

    .c-slider {
        width: 100%;
    }

    .b-onglet_nav{
        display: flex;
        align-items: flex-end;
        justify-content: flex-start;
        width: 100%;

        .b-onglet_nav_title{
            cursor: pointer;
            width: calc(100% - (#{rem($unit-tiny)} * 2));
            justify-content: center;

            &::after {
                display: none;
            }

            &.is-active{
                background-color: $primary;
                color: $white;
            }
        }
    }

    .c-button_label {
        z-index: auto;
    }

    .b-onglet_container{
        overflow: hidden;

        display: grid;

        .b-onglet_text{
            transition: 0.5s ease;

            grid-row: 1;
            grid-column: 1;
        }
    }
}
