// ==========================================================================
// settings / config / colors
// ==========================================================================

// palette
// =============================================================================
$white: #ffffff;
$black: #16344e;

$primary: #0088cf;
$second: #85bb25;
$third: #e49b00;
$fourth: #d10c12;

$third-hover: #e28403;
$primary-light: #ebf6fb;

$black-dark: #0d2336;

$green: #85bb25;
$red: #d10c12;

$grey: rgba($black, 0.35);
$grey-light: #f0f0f0;

$black-overlay-light: rgba(0, 0, 0, 0.5);


// specific
// =============================================================================
// selection
$selection-text-color:       $white;
$selection-background-color: $black;

// social colors
// =============================================================================
$facebook-color:  #3b5998;
$instagram-color: #e1306c;
$youtube-color:   #cd201f;
$twitter-color:   #1da1f2;

$dossers: (
    rouge: #d86e5b,
    orange: #e8a24f,
    violet: #5967d3,
    bleu: #0088cf,
    vertclair: #67af9e,
    mauve: #ac70d8,
    bleufonce: #2a5eb1,
    jaune: #e3be6a,
    vert: #81c359,
);