.c-badge {
    padding: rem($unit-tinier) rem($unit-tiny);
    border-radius: rem(45px);
    background-color: $white;
    text-transform: uppercase;
    font-size: rem($font-size-small);
    flex-shrink: 0;
    &.-black {
        background-color: $black;
        color: $white;
    }
    &.-primary {
        background-color: $primary;
        color: $white;
    }

    &.-round {
        width: rem($font-size);
        height: rem($font-size);
        border-radius: 50%;
        padding: 0;
    }
}