.b-etapes {
    .b-etapes_item {
        position: relative;
    }
    .b-etapes_item_step {
        position: absolute;
        top: 0;
        transform: translateY(-50%);
    }
}
