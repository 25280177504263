// ==========================================================================
// Form
// ==========================================================================
.c-form {

}

.c-form_item {
    position: relative;

    &.-button {
      overflow: hidden;
      transition: $speed $easing2;
    }

    @include u-hocus {
      &.-button  {
        
      }
    }
}

// Label
// ==========================================================================
.c-form_label {
  position: absolute;
  display: block;
  top: 50%;
  transform: translateY(-50%);
  left: rem($unit-tiny);
  transition: 0.3s $easing2;
  pointer-events: none;
}

.c-form_item {
  &.is-active {
    label {
      font-size: rem($font-size-small);
      transform: translateY(calc(-100% - 0.5rem));
    }
  }
}

.c-form_textarea {
  .c-form_label {
    top: 1.5rem;
  }
}

.c-form_icon {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  padding: rem($unit-tinier) rem($unit-tiny);
  display: flex;
  z-index: 2;
  transition: $speed $easing2;
}

// Input
// ==========================================================================
$input-icon-color: 424242; // No #

.c-form_input {
    padding: rem($unit-tiny);
    //border: 1px solid lightgray;
    background-color:rgba($black, 0.08);
    border-radius: 4px;

    &.-transparent {
      background-color: transparent;
      border: 1px solid rgba($black, 0.16);
      border-radius: 4px;
    }

    &.-white {
      background-color: $white;
    }

    /*&:hover {
        border-color: darkgray;
    }

    &:focus {
        border-color: dimgray;
    }*/

    /*&::placeholder {
        color: gray;
    }*/
}

// Checkbox
// ==========================================================================
$checkbox: rem(18px);
$checkbox-icon-color: $input-icon-color;

.c-form_checkboxLabel {
    // @extend .c-form_label;

    position: relative;
    display: inline-block;
    padding-left: ($checkbox + rem(10px));
    cursor: pointer;

    &::before, &::after {
        position: absolute;
        top: 50%;
        left: 0;
        display: inline-block;
        margin-top: (-$checkbox / 2);
        padding: 0;
        width: $checkbox;
        height: $checkbox;
        content: "";
    }

    &::before {
        background-color: $white;
        border: 1px solid $black;
        border-radius: 3px;
    }

    &::after {
        border-color: transparent;
        background-color: transparent;
        background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20x%3D%220%22%20y%3D%220%22%20width%3D%2213%22%20height%3D%2210.5%22%20viewBox%3D%220%200%2013%2010.5%22%20enable-background%3D%22new%200%200%2013%2010.5%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpath%20fill%3D%22%23#{$checkbox-icon-color}%22%20d%3D%22M4.8%205.8L2.4%203.3%200%205.7l4.8%204.8L13%202.4c0%200-2.4-2.4-2.4-2.4L4.8%205.8z%22%2F%3E%3C%2Fsvg%3E");
        background-position: center;
        background-size: rem(12px);
        background-repeat: no-repeat;
        opacity: 0;
    }

    &:hover {
        &::before {
            border-color: darkgray;
        }
    }

    .c-form_checkbox:focus + & {
        &::before {
            border-color: dimgray;
        }
    }

    .c-form_checkbox:checked + & {
        &::after {
            opacity: 1;
        }
    }
}

.c-form_checkbox {
    position: absolute;
    width: 0;
    opacity: 0;
}

// Radio
// ==========================================================================
$radio-icon-color: $input-icon-color;

.c-form_radioLabel {
    @extend .c-form_checkboxLabel;

    &::before, &::after {
        border-radius: 50%;
    }

    &::after {
        background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20x%3D%220%22%20y%3D%220%22%20width%3D%2213%22%20height%3D%2213%22%20viewBox%3D%220%200%2013%2013%22%20enable-background%3D%22new%200%200%2013%2013%22%20xml%3Aspace%3D%22preserve%22%3E%3Ccircle%20fill%3D%22%23#{$radio-icon-color}%22%20cx%3D%226.5%22%20cy%3D%226.5%22%20r%3D%226.5%22%2F%3E%3C%2Fsvg%3E");
        background-size: rem(6px);
    }
}

.c-form_radio {
    @extend .c-form_checkbox;
}

// Select
// =============================================================================
$select-icon: rem(32px);
$select-icon-color: $input-icon-color;

.c-form_select {
    position: relative;
    cursor: pointer;

    border: 2px solid $black;
    border-radius: 4px;

    .c-form_select_dropdown {
      position: absolute;
      width: $select-icon;
      right: rem($unit-tiny);
      top: 50%;
      transform: translateY(-50%);
    }

    // &::after {
    //     position: absolute;
    //     top: 0;
    //     right: 0;
    //     bottom: 0;
    //     z-index: 2;
    //     width: $select-icon;
    //     background-image: url("data:image/svg+xml,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20version%3D%221.1%22%20x%3D%220%22%20y%3D%220%22%20width%3D%2213%22%20height%3D%2211.3%22%20viewBox%3D%220%200%2013%2011.3%22%20enable-background%3D%22new%200%200%2013%2011.3%22%20xml%3Aspace%3D%22preserve%22%3E%3Cpolygon%20fill%3D%22%23#{$select-icon-color}%22%20points%3D%226.5%2011.3%203.3%205.6%200%200%206.5%200%2013%200%209.8%205.6%20%22%2F%3E%3C%2Fsvg%3E");
    //     background-position: center;
    //     background-size: rem(8px);
    //     background-repeat: no-repeat;
    //     content: "";
    //     pointer-events: none;
    // }
}

.c-form_select_input {
    @extend .c-form_input;

    background-color: transparent;

    position: relative;
    z-index: 1;
    padding-right: $select-icon;
    cursor: pointer;
}

// Textarea
// =============================================================================
.c-form_textarea {
    @extend .c-form_input;

    min-height: rem(200px);
}

.c-form_radio2 {
  [type="radio"]:not(:checked), [type="radio"]:checked {
    position: absolute;
    opacity: 0;
    pointer-events: none;
  }

  [type="radio"]:not(:checked)+span, [type="radio"]:checked+span {
    position: relative;
    padding-left: 35px;
    cursor: pointer;
    display: inline-block;
    height: 25px;
    line-height: 25px;
    font-size: 1rem;
    transition: .28s ease;
    user-select: none;
  }

  [type="radio"]+span:before, [type="radio"]+span:after {
    content: '';
    position: absolute;
    left: 0;
    top: 0;
    margin: 4px;
    width: 16px;
    height: 16px;
    z-index: 0;
    transition: .28s ease;
  }

  [type="radio"]:not(:checked)+span:before, [type="radio"]:not(:checked)+span:after, [type="radio"]:checked+span:before, [type="radio"]:checked+span:after, [type="radio"].with-gap:checked+span:before, [type="radio"].with-gap:checked+span:after {
    border-radius: 50%;
  }

  [type="radio"]:checked+span:before {
    border: 2px solid transparent;
  }

  [type="radio"]:checked+span:after, [type="radio"].with-gap:checked+span:after {
    background-color: $primary;
  }

  [type="radio"]:checked+span:after {
    transform: scale(1.02);
  }

  [type="radio"]:not(:checked)+span:before, [type="radio"]:not(:checked)+span:after {
    border: 2px solid $black;
  }

  [type="radio"]:not(:checked)+span:after {
    transform: scale(0);
  }
}


.error {
  color: $red;
}
.success {
  color: $green;
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  cursor: pointer;
}

.no-js .inputfile + label {
  display: none;
}

.form-group-file {
  padding: rem($unit-small);
  border: 1px solid $black;
  border-radius: 4px;
}

.c-form_file_label {
  display: grid;
  grid-template-columns: rem(70px) 1fr;
  gap: rem($unit-small);
  align-items: center;
}