.b-galerie {
  overflow: hidden;
  .b-galerie_slider_wrap {
    display: grid;
    grid-template-columns: rem(48px) 1fr rem(48px);
    align-items: center;
    gap: rem($unit);
    @media (max-width: $to-small) {
      grid-template-columns: 1fr;
    }

    .c-slider {
      min-width: 100%;
      
    }
  }
  .b-galerie_navigation_button {
    position: relative;
    z-index: 2;
  }
  .b-galerie_wrap {
    .b-galerie_grid {
      display: grid;
      grid-auto-rows: 1fr;
      grid-gap: rem($unit-small);
    }
    .b-galerie_item {
      width: 100%;
      height: 100%;
      @media (max-width: $to-medium) {
        // max-height: rem(400px);
      }
      @media (max-width: $to-small) {
        .o-ratio {
          &::before {
            padding-bottom: calc(100% + #{rem($unit-small)})!important;
          }
        }
      }
      @media (min-width: $from-small) {
        .o-ratio {
          &.-gap {
            &::before {
              padding-bottom: calc(100% + #{rem($unit-small)});
            }
          }
        }
      }
    }
    .b-galerie_type1 {
      grid-template-columns: 1fr 1fr;
      @media (max-width: $to-small) {
        grid-template-columns: 1fr;
        grid-auto-rows: auto;
      }
    }
    .b-galerie_type2 {
      grid-template-columns: 1fr 1fr;
      @media (max-width: $to-small) {
        grid-template-columns: 1fr;
        grid-auto-rows: auto;
      }
    }
    .b-galerie_type3 {
      grid-template-columns: 1fr;
    }
    .b-galerie_type4 {
      grid-template-columns: 1fr 1fr;
      @media (max-width: $to-small) {
        grid-template-columns: 1fr;
        grid-auto-rows: auto;
      }
    }
    .b-galerie_sub {
      grid-template-columns: repeat(5, 1fr);
      @media (max-width: $to-small) {
        grid-template-columns: 1fr;
      }
    }
  }
  .b-galerie_wrap {
    .o-container {
      position: relative;
      z-index: auto;
    }
    .c-slider_container {
      position: relative;
      z-index: 2;
    }
    &.-slider {
      width: 110vw;
      transform: rotate(-4deg) translateX(-5vw);
      .b-galerie_slider_item {
        position: relative;
        border-radius: 5px;
        overflow: hidden;
        opacity: 0.32;
        transition: $speed $easing;
        background-color: $grey;
        figure {
          height: rem(320px);
        }
        .c-button {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
      .swiper-slide-active {
        .b-galerie_slider_item {
          opacity: 1;
        }
      }
      .swiper-navigation_button {
        position: absolute;
        top: 50%;
        z-index: 2;
        transform: translateY(-50%);
        &.-prev {
          left: rem(-$unit);
          svg {
            transform: rotate(180deg);
          }
        }
        &.-next {
          right: rem(-$unit);
        }
      }
    }
  }
  .w100 {
    width: 100%;
  }
  .c-modal_wrap {
    display: none;
  }
}
