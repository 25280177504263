.b-intro {
    position: relative;
    .b-intro_image-wrap {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 50vw;
        height: 100%;
        filter: drop-shadow(0 10px 30px rgba(0, 0, 0, 0.1));
        .b-intro_image {
            clip-path: url(#intro-clip-path);
            height: 100%;
        }
        // &::after {
        //     content: "";
        //     position: absolute;
        //     z-index: 1;
        //     top: 0;
        //     left: 0;
        //     width: 100%;
        //     height: 100%;
        //     background: linear-gradient(45deg, rgba($black, 0.8), transparent);
        // }
    }

    .b-intro_deco {
        position: absolute;
        top: rem(-$unit);
        right: rem(-$unit-large);
        z-index: -1;
    }

    .b-intro_icon {
        position: relative;
        padding: rem($unit) rem($unit) 0 0;
        svg {
            width: rem(70px);
        }
        .b-intro_icon_bg {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            z-index: -1;
            width: rem(300px);
            height: rem(300px);
            border-radius: 50%;
        }
    }

    .b-intro_content {
        position: relative;
        z-index: 2;
    }

    .b-intro_certif {
        background-color: $primary-light;
        display: inline-block;
        padding: rem($unit-tiny) rem($unit-small);
        border-radius: 8px 8px 0 0;
        img {
            max-height: rem(90px);
        }
    }
}