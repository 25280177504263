.c-heading {
    font-family: $font-montserrat;
    &.-h1 {
        font-size: rem($font-size-h1);
        line-height: $font-size-h1 + 20px;
        @media (max-width: $to-small) {
            font-size: calc(#{rem($font-size-h1)} - 60px);
            line-height: $font-size-h1 - 40px;
        }
    }

    &.-h2 {
        font-size: rem($font-size-h2);
        line-height: $font-size-h2 + 2px;
        @media (max-width: $to-small) {
            font-size: calc(#{rem($font-size-h2)} - 40px);
            line-height: $font-size-h2 - 38px;
        }
    }

    &.-h3 {
        font-size: rem($font-size-h3);
        line-height: $font-size-h3 + 2px;
        @media (max-width: $to-small) {
            font-size: calc(#{rem($font-size-h3)} - 15px);
            line-height: $font-size-h3 - 13px;
        }
    }

    &.-medium {
        font-size: rem($font-size-medium);
        line-height: $font-size-medium + 2px;
        @media (max-width: $to-small) {
            font-size: calc(#{rem($font-size-medium)} - 10px);
            line-height: $font-size-medium - 8px;
        }
    }

    &.-h4 {
        font-size: rem($font-size-h4);
        line-height: $font-size-h4 + 2px;
        // @media (max-width: $to-medium) {
        //     font-size: calc(#{rem($font-size-h4)} - 4px);
        // }
    }

    &.-h5 {
        font-size: rem($font-size-h5);
        line-height: $font-size-h5 + 2px;
    }

    &.-h6 {
        font-size: rem($font-size-h6);
    }

    &.-bigger {
        font-size: rem($font-size-bigger);
        line-height: 71.5%;
        // @media (max-width: $to-small) {
        //     font-size: calc(#{rem($font-size-h1)} - 10px);
        // }
    }

    &.-extra {
        font-size: rem($font-size-extra);
        line-height: 71.5%;
        @media (max-width: $to-small) {
            font-size: rem(30px);
        }
        @media (max-width: $to-medium) {
            font-size: rem(60px);
        }
    }
}

h1, h2, h3, h4, h5, h6 {
    font-size: inherit;
    font-weight: inherit;
}
