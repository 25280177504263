.c-slider{
    &.-slide-hidden{
        overflow: hidden;
    }
}

.c-slider_container{
    overflow: hidden;
}

.swiper-navigation_button {
    &.-prev {
        svg {
            transform: rotate(180deg);
        }
    }
}

.swiper-slide {
    height: auto!important;
}

.swiper-pagination-bullet {
    width: rem(14px)!important;
    height: rem(14px)!important;
    border: 1px solid $primary;
    opacity: 1!important;
    background-color: $primary!important;
    margin-right: 0.2rem;
}
.swiper-pagination-bullet-active {
    border-color: $black!important;
    background-color: $black!important;
    opacity: 1!important;
}

.swiper-pagination {
    position: relative!important;
    margin: 0;
}