.b-login {
    .b-login_wrap {
        border: 1px solid $black;
        border-radius: 5px;
        position: relative;
    }
    .c-button {
        transform: translate(-50%, 50%);
        position: absolute;
        bottom: 0;
        left: 50%;
    }

    .b-login_deco {
        position: absolute;
        width: 100%;
        left: 0;
        top: 0;
        z-index: -1;
        pointer-events: none;
        svg {
            width: 100%;
        }
    }
}