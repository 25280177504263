.c-header {
    position: fixed;
    top: 0;
    right: 0;
    left: 0;
    height: rem($header-height);
    z-index: 10;

    background: linear-gradient(rgba($black, 0.88), transparent);
    pointer-events: none;

    * {
        pointer-events: all;
    }

    .c-header_action {
        position: relative;
        z-index: 10;
    }

    @media (min-width: $from-small) {
        padding: 0 rem($unit);
    }
    @media (max-width: $to-small) {
        padding: 0 rem($unit-small);
    }
}

.has-navOpen, .has-navAdherentOpen, .has-navSearchOpen, .has-navLoginOpen {
    .c-header {
        .c-header_link {
            color: $black;
        }
    }
}