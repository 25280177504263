.b-fichiers {
    .b-fichiers_link {
        padding: rem($unit-tiny);
        border: 2px solid $black;
        border-radius: rem(8px);
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:hover {
            .c-button {
                &::before {
                    top: 0;
                }
            }
        }
    }
    .b-fichiers_ext {
        font-size: rem($font-size-small);
        text-transform: uppercase;
    }
}