.b-relation_formations {
    .b-relation_formations_btn_wrap {
        position: relative;
        &::before {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 50vw;
            height: 100%;
            background-color: $black;
            transition: $speed $easing2;
            border-radius: 3px;
        }
        .b-relation_formations_btn {
            text-align: left;
            justify-content: space-between;
            width: 100%;
            display: grid;
            grid-template-columns: 1fr rem(32px);
            .c-button_icon {
                border: 1.5px solid $white;
                border-radius: 50%;
                color: $white;
                &::before {
                    background-color: transparent;
                }
            }
        }
        &.is-active {
            &::before {
                background-color: $third;
            }
            .b-relation_formations_btn {
                background-color: $third;
                color: $black;
                .c-button_icon {
                    border-color: $black;
                    color: $black;
                }
            }
        }
    }

    .b-relation_formations_slider_wrap {
        display: grid;
        grid-template-columns: 1fr rem(32px);
        gap: rem($unit-small);
        @media (max-width: $to-small) {
            grid-template-columns: 1fr;
            .b-relation_formations_slider_nav {
                flex-direction: row;
            }
        }
    }
}