.b-relation_temoignages {
    .b-relation_temoignages_slider_wrap {
        display: grid;
        grid-template-columns: 1fr rem(60px + $unit-tiny);
        gap: rem($unit-small);
        .c-slider {
            min-width: 100%;
        }
    }
    .b-relation_temoignages_btn {
        opacity: 0.4;
        transition: $speed $easing2;
        text-align: left;
        &.is-active, &:hover {
            opacity: 1;
        }
    }
    .swiper-slide {
        width: auto !important;
    }
}