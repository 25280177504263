.c-onglet {
    .c-onglet_body {
        display: grid;
        .c-onglet_item {
            grid-column: 1;
            grid-row: 1;
            overflow: hidden;
        }
    }
    .c-onglet_head {
        .c-button {
            border-radius: 0;
            border-left: 1px solid rgba($white, 0.24);
            &:first-of-type {
                border-bottom-left-radius: 4px;
                border-left: none;
            }
            &:last-of-type {
                border-bottom-right-radius: 4px;
            }
            // &:not(.is-active) {
            //     border-left: 1px solid rgba();
            // }
        }
    }
}